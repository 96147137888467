<template>
  <div>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deletePackageSize($event, null , true)"
      @emitShowCurrentRecords="fetchAllPackageSizes()"
    />
    <vs-popup
      :title="dialogTitle"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row justify-center mt-5">
            <div class="vx-col w-full">
              <ValidationProvider
                name="الحجم"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الحجم</label>
                <vs-input
                  placeholder="الحجم"
                  name="size"
                  v-model="packageSize.size"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <vs-col
            v-if="!isUpdate"
            vs-type="flex"
            vs-justify="start"
            vs-align="right"
            vs-w="12"
          >
            <vs-row class="mt-5">
              <vs-card
                v-for="(packageClass , index) in packageSize.package_classes"
                :key="packageClass.id"
              >
                <div class="vx-col w-full">
                  <ValidationProvider
                    name="فئة التغليف"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label> فئة التغليف</label>
                    <vs-input
                      placeholder="الفئة:"
                      name="class"
                      v-model="packageClass.class"
                      class="w-full"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="vx-row justify-center mt-5">
                  <div class="vx-col w-full">
                    <ValidationProvider
                      name="سعر فئة التغليف"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label>سعر فئة التغليف</label>
                      <vs-input
                        type="number"
                        placeholder="السعر"
                        name="price"
                        v-model="packageClass.price"
                        class="w-full"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col  w-full text-center">
                    <h4 class="my-5">
                      الصورة
                    </h4>
                    <image-uploader
                      @fileUploaded="fileUploaded"
                      :image-preview="imagePreview[index]"
                      :is-image-required="isImageRequired"
                      :index="index"
                      ref="imageUploader"
                    />
                  </div>
                </div>
                <vs-row
                  v-if="!isFirstPackageSize"
                  class="mt-5"
                >
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="4"
                    vs-xs="12"
                  >
                    <vs-button
                      color="danger"
                      type="gradient"
                      icon-pack="feather"
                      icon="icon-x"
                      @click="deletePackageClass(index)"
                    />
                  </vs-col>
                </vs-row>
              </vs-card>
            </vs-row>
          </vs-col>
          <vs-col
            v-if="!isUpdate"
            vs-lg="12"
            class="mb-6"
          >
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-plus"
              size="small"
              @click="addPackageClass"
            >
              اضافة فئة تغليف
            </vs-button>
          </vs-col>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
import AgButton from "../../../shared/shared-components/ag-grid/AgButton";
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import imageUploader from '../../../shared/shared-components/ImageUploader';
import utilities from '../../../shared/utilities/index';

const packageSizeRepo = RepositoryFactory.get('packageSize');

export default {
  name: "PackageSize",
  components: {
    AgTable,
    imageUploader
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      isUpdate: false,
      isImageRequired: true,
      imagePreview: [],
      dialogTitle: '',
      isFirstPackageSize: true,
      packageSize: {
        id: null,
        size: '',
        package_classes: [
          {
            id: null,
            class: '',
            price: '',
            images: []
          }]
      }
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          imagePreview: null,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: 'الحجم',
          field: 'size',
          filter: 'agTextColumnFilter',
          width: 250,
        }, {
          headerName: 'فئات التغليف',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'فئات التغلف',
            type: 'gradient',
            click(id) {
              self.rowClicked(id);
            }
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deletePackageSize(id, rowIndex);
            },
            editRecord: function (packageSize) {
              self.editRecord(packageSize);
            },
            actions: ['edit', 'delete']
          },

        },
      ];
    },
    rowClicked(id) {
      this.$router.push(`/ecommerce/packageSize/${id}/packageClasses`);
    },
    fileUploaded(file, index) {
      if (file !== null) {
        this.isLoading = true;
        packageSizeRepo.uploadImage(this.$objectToFD({file: file})).then((response) => {
          this.packageSize.package_classes[index].images.push(response.data);
          this.isLoading = false;
        });
      }
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    // used to fire the dialog which response to add a new record in the table
    createRecord() {
      this.isUpdate = false;
      utilities.initObjectMembers(this.packageSize);
      this.packageSize.package_classes = [];
      this.packageSize.package_classes.push({class: '', price: '', images: []});
      for (let i = 0; i < this.$refs.imageUploader.length; i++)
        this.$refs.imageUploader[i].clearImage();
      this.imagePreview = [];
      this.dialogTitle = 'اضافة حجم تغليف';
      this.openPopup();
    },

    // used to fire the dialog which response to edit current record in the table
    editRecord(packageSize) {
      for (let i = 0; i < this.$refs.imageUploader.length; i++)
        this.$refs.imageUploader[i].clearImage();
      this.dialogTitle = 'تعديل حجم التغليف';
      Object.assign(this.packageSize, packageSize);
      for (let i = 0; i < this.packageSize.package_classes.length; i++)
        for (let j = 0; j < this.packageSize.package_classes[i].images.length; j++)
          this.imagePreview[i] = this.packageSize.package_classes[i].images[j];
      this.isUpdate = true;
      this.openPopup();
    },
    addPackageClass() {
      this.isFirstPackageSize = false;
      this.packageSize.package_classes.push({
          class: '',
          price: '',
          images: []
        }
      );
    },
    deletePackageClass(index) {
      this.packageSize.package_classes.splice(index, 1);
    },
    onSubmit() {
      this.isLoading = true;
      if (this.packageSize.id !== null) {
        this.updatePackageSize(this.packageSize);
      } else {
        this.storePackageSize(this.packageSize);
      }
    },

    storePackageSize(packageSize) {
      packageSizeRepo.storePackageSize(this.$objectToFD(packageSize)).then((response) => {
        this.rowData.unshift(response.data.data);
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    updatePackageSize(packageSize) {
      packageSizeRepo.updatePackageSize(this.$objectToFD(packageSize), packageSize.id).then((response) => {
        let index = this.rowData.map((m) => {
          return m.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isUpdate = false;
        this.isLoading = false;
      });
    },
    deletePackageSize(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      packageSizeRepo.deletePackageSize(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllPackageSizes();
          else {
            this.rowData.splice(index, 1);
            this.rerender();
          }
        }
        this.isLoading = false;
      });
    },
    fetchAllPackageSizes() {
      packageSizeRepo.fetchAllPackageSizes().then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
    fileUpload(e, index) {
      this.isLoading = true;
      packageSizeRepo.uploadImage(this.$objectToFD({file: e.target.files[0]})).then((response) => {
        this.packageSize.package_classes[index].images.push(response.data);
        this.isLoading = false;
      });
    }
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllPackageSizes();

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>

<style scoped>

</style>
